html {
  scroll-behavior: smooth;
}

body {
  font-size: 1.5rem;
}

:root {
    --active-brightness: 0.85;
    --border-radius: 5px;
    --box-shadow: 2px 2px 10px;
    --color: #118bee;
    --color-accent: #118bee15;
    --color-bg: #fff;
    --color-bg-secondary: #e9e9e9;
    --color-link: #118bee;
    --color-secondary: #920de9;
    --color-secondary-accent: #920de90b;
    --color-shadow: #f4f4f4;
    --color-table: #118bee;
    --color-text: #000;
    --color-text-secondary: #999;
    --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    --hover-brightness: 1.2;
    --justify-important: center;
    --justify-normal: left;
    --line-height: 1.5;
    --width-card: 285px;
    --width-card-medium: 460px;
    --width-card-wide: 800px;
    --width-content: 1080px;
}

/* Styles */

.main-bg { color: #CCC; }

.input-group {
  display: flex;
}

.input-group input {
  padding: 0;
  height: 2em;
  padding-left: 1em;

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group input:nth-of-type(1) {
  margin: 0;
  border-bottom-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
}

.input-group button {
  margin: 0;
  padding: 0 1em;
  height: 3.1em;
  min-width: 5em;

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.text-centered { text-align: center; }
.flex { display: flex; }
.flex.justify-center { justify-content: center; }
.flex.align-items-center { align-items: center; }
.flex.col { flex-direction: column; }
.flex.centered {
  justify-content: center;
  align-items: center;
}

.margin-v-sm {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.margin-v-md {
  margin-top: 1em;
  margin-bottom: 1em;
}

form .nonce { display: none; }

form.listmonk-form {
  max-width: none;
}

.hidden { display: none; }
