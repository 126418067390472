html {
  scroll-behavior: smooth;
}

body {
  font-size: 1.5rem;
}

:root {
  --active-brightness: .85;
  --border-radius: 5px;
  --box-shadow: 2px 2px 10px;
  --color: #118bee;
  --color-accent: #118bee15;
  --color-bg: #fff;
  --color-bg-secondary: #e9e9e9;
  --color-link: #118bee;
  --color-secondary: #920de9;
  --color-secondary-accent: #920de90b;
  --color-shadow: #f4f4f4;
  --color-table: #118bee;
  --color-text: #000;
  --color-text-secondary: #999;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --hover-brightness: 1.2;
  --justify-important: center;
  --justify-normal: left;
  --line-height: 1.5;
  --width-card: 285px;
  --width-card-medium: 460px;
  --width-card-wide: 800px;
  --width-content: 1080px;
}

.main-bg {
  color: #ccc;
}

.input-group {
  display: flex;
}

.input-group input {
  border-radius: 0;
  height: 2em;
  padding: 0 0 0 1em;
}

.input-group input:first-of-type {
  border-top-left-radius: .25em;
  border-bottom-left-radius: .25em;
  margin: 0;
}

.input-group button {
  border-radius: 0 .25em .25em 0;
  min-width: 5em;
  height: 3.1em;
  margin: 0;
  padding: 0 1em;
}

.text-centered {
  text-align: center;
}

.flex {
  display: flex;
}

.flex.justify-center {
  justify-content: center;
}

.flex.align-items-center {
  align-items: center;
}

.flex.col {
  flex-direction: column;
}

.flex.centered {
  justify-content: center;
  align-items: center;
}

.margin-v-sm {
  margin-top: .5em;
  margin-bottom: .5em;
}

.margin-v-md {
  margin-top: 1em;
  margin-bottom: 1em;
}

form .nonce {
  display: none;
}

form.listmonk-form {
  max-width: none;
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.4681b407.css.map */
